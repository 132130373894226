<template>
  <v-row>
    <v-col cols="12" style="padding:0px !important;">
      <v-carousel
            class="hidden-sm-and-down"
            :show-arrows="false"
            hide-delimiters
            interval="12000"
            height="500"
        >
            <v-carousel-item
            v-for="(item, i) in slides"
            :key="i"
            :src="item.src"
            reverse-transition="fade-transition"
            transition="fade-transition"
            >
            <div class="overlay">
                <v-row class="fill-height" style="padding-top:150px;">
                <v-col cols="11" md="6" offset="1" style="color:#ffffff;">
                    <v-layout wrap class="text-h2" style="color:#ffffff;">
                    <b>{{ item.title }}</b>
                    </v-layout>
                    <p class="ma-5" style="font-size: 18px;">
                    {{ item.text }}
                    </p>
                    <!-- <v-layout wrap>
                    <v-btn rounded color="#72bd61" dark>{{ item.btnText }}</v-btn>
                    </v-layout> -->
                </v-col>
                </v-row>
            </div>
            </v-carousel-item>
        
      </v-carousel>
      <v-carousel
            class="hidden-md-and-up"
            cycle
            :show-arrows="false"
            hide-delimiters
            height="200px"
            interval="10000"
        >
            <v-carousel-item
            v-for="(item, i) in slides"
            :key="i"
            :src="item.src"
            transition="fade"
            reverse-transition="fade"
            >
            <div class="overlay">
                <v-row class="fill-height pt-5">
                <v-col cols="8" offset="1" style="color:#ffffff;">
                    <v-layout wrap class="text-h6" style="color:#ffffff;">
                    {{ item.title }}
                    </v-layout>
                    <p>
                    {{ item.text }}
                    </p>
                    <v-layout wrap>
                    <v-btn rounded color="#72bd61" dark small>{{
                        item.btnText
                    }}</v-btn>
                    </v-layout>
                </v-col>
                </v-row>
            </div>
            </v-carousel-item>
      </v-carousel>

      <v-container fluid >
        <v-layout wrap>
              <v-col cols="12" align="center">
                <h2><b>Be at Peace whenever you Order</b></h2>
              </v-col>
              <v-col cols="12">
                  <p>
                    qikPharma secure payment allows payments via credit and debit cards issued by Visa, MasterCard, American Express and others. 
                    This is a popular payment method for buyers as it offers ease of use and does not require setting up additional payment methods or services.
                  </p>
              </v-col>
        </v-layout>

        <v-layout wrap>
            <v-col cols="12" md="4" offset-md="2" align="left">
                    <v-icon
                        style="color:#7CBF46 !important; font-size:70px !important"
                        dark
                        >mdi-cash
                    </v-icon> <span style="font-size:25px !important"><b>Mobile Money</b></span>
            </v-col>
             <v-col cols="12" md="5" offset-md="1" align="left">
                    <v-icon
                        style="color:#7CBF46 !important; font-size:70px !important"
                        dark
                        >mdi-timer-check
                    </v-icon> <span style="font-size:25px !important"><b>Pay on Delievery</b></span>
            </v-col>
        </v-layout>

        <v-divider></v-divider>



      </v-container>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "About",

  data: () => ({
      slides: [
        {
          src: "slider/payment_info.jpeg",
          title: "Easy Payment ",
          text: "The Convenient Pharmactical Shopping experience that allows you pay at your doorstep",
          btnText: "Learn How it Works",
          link: "#",
        },
      ],
  })
};
</script>

<style scoped>
.overlay {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
}

.box1 {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff;
}

.box2 {
  background: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  color: #171a17;
}

.barcodeTitle {
  color: #000000;
  font-size: 16px;
  text-align: center;
}

.barcodeText {
  color: #c2c2c2;
  font-size: 12px;
  margin-top: 15px;
  text-align: center;
}

.centerImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
}

p {
  line-height: 1.6;
}

li {
  line-height: 1.6;
}

</style>
